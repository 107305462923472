var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-row',{attrs:{"align-v":"stretch"}},[_c('b-col',{attrs:{"sm":"12","md":"7","xl":"8"}},[_c('b-card',{staticClass:"h-100"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","sm":"10"}},[(Object.keys(_vm.selectedCategory).length !== 0)?_c('div',[_c('b-row',{staticClass:"mb-1",on:{"click":function () {
                    _vm.selectedCategory = {};
                  }}},[_c('b-col',{staticStyle:{"cursor":"pointer"}},[(_vm.$i18n.isRTL)?_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"14"}}):_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"14"}})],1)],1),_c('b-row',{staticClass:"mb-1",staticStyle:{"font-size":"1.5rem"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t("topic")))]),_c('span',[_vm._v(":")])]),_c('b-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.selectedCategory.title)+" ")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"title","label":_vm.$t('createTicketPage.form.ticket_title'),"label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('forex.platform'),"label-for":"platform"}},[_c('validation-provider',{attrs:{"name":_vm.$t('forex.platform'),"vid":"platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"platform","options":_vm.platforms,"label":"name","clearable":false},on:{"input":_vm.onAccountChange},model:{value:(_vm.form.platform),callback:function ($$v) {_vm.$set(_vm.form, "platform", $$v)},expression:"form.platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":_vm.$t('forex.account-number'),"vid":"account-number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"account-number","label":_vm.$t('forex.account-number'),"label-for":"account-number"}},[_c('v-select',{attrs:{"id":"account-number","options":_vm.accountsItems,"clearable":false,"label":"account","dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.form.platformAccount),callback:function ($$v) {_vm.$set(_vm.form, "platformAccount", $$v)},expression:"form.platformAccount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('context'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"context","label":"","label-for":"context"}},[_c('b-form-textarea',{attrs:{"id":"context","label":_vm.$t('createTicketPage.form.ticket_context'),"placeholder":_vm.$t('createTicketPage.form.ticket_context_description'),"rows":"8"},model:{value:(_vm.form.context),callback:function ($$v) {_vm.$set(_vm.form, "context", $$v)},expression:"form.context"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-row',{attrs:{"align-v":"center","align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","lg":"auto"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('image'),"rules":'image|size:' + _vm.MAX_IMAGE_UPLOAD_SIZE},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var validate = ref.validate;
return [_c('div',{staticClass:"d-flex flex-row-reverse"},[(_vm.form.file)?_c('feather-icon',{staticClass:"text-danger stroke-current",attrs:{"icon":"XIcon","size":"28","variant":"danger"},on:{"click":function($event){_vm.form.file = null}}}):_vm._e(),_c('b-form-file',{attrs:{"plain":"","accept":"image/png, image/jpg, image/jpeg"},on:{"change":validate},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-1 mt-lg-0",attrs:{"cols":"12","lg":"auto"}},[_c('b-button',{staticClass:"flex-end",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[(!_vm.isLoading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("createTicketPage.form.btn")))]):_c('b-spinner',{attrs:{"small":"","variant":"light","type":"grow"}})],1)],1)],1)],1)]}}],null,false,3924472340)})],1):_c('div',[_c('b-row',[_vm._v(" "+_vm._s(_vm.$t("createTicketPage.category_title"))+" ")]),_vm._l((_vm.categories),function(item,index){return _c('b-row',{key:index,staticClass:"w-100 border border-primary ml-0 my-2 py-2 rounded-lg shadow-sm categoryItem",attrs:{"align-h":"between"},on:{"click":function($event){return _vm.categorySelecting(item)}}},[_c('b-col',{staticStyle:{"font-size":"1.2rem"},attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")]),_c('b-col',{attrs:{"cols":"auto"}},[(_vm.$i18n.isRTL)?_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"14"}}):_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"14"}})],1)],1)})],2)])],1)],1)],1),_c('b-col',{attrs:{"sm":"12","md":"5","xl":"4"}},[_c('b-card',{staticClass:"h-100 margin-query"},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t("createTicketPage.desc_title"))+" ")]),_vm._l((_vm.$t('createTicketPage.description')),function(item){return _c('b-card-text',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }